import { lazy } from 'react';
import { PERMISSION_DIRECTORY, PERMISSION_MAP } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_DIRECTORY },
  path: 'directory',
  urlParams: '/:dirType?',
  title: 'Directory',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/studio',
      component: () => null,
      title: 'Studios / Playout',
      pageTitle: 'Connected Studios and Playout Locations',
    },
    {
      path: '/sport-venues',
      component: () => null,
      title: 'Sport-Venues',
      pageTitle: 'Connected Venues',
    },
    {
      path: '/colleges',
      component: () => null,
      title: 'Colleges',
      pageTitle: 'Connected Colleges',
    },
    {
      path: '/tv-stations',
      component: () => null,
      title: 'TV-Stations',
      pageTitle: 'Connected TV-Stations',
    },
    {
      path: '/broadcasters',
      component: () => null,
      title: 'Broadcasters',
      pageTitle: 'Connected Broadcasters',
    },
    {
      path: '/headends-mvpd',
      component: () => null,
      title: 'Headends-MVPD',
      pageTitle: 'Connected Headends / MVPDs',
    },
    {
      path: '/teleports-switching-hubs',
      component: () => null,
      title: 'Teleports-Switching-Hubs',
      pageTitle: 'Connected Teleports and Hubs',
    },
    {
      path: '/locations',
      component: () => null,
      title: 'Locations',
      pageTitle: 'General Connectivity List',
    },
    {
      path: '/map-view',
      auth: { role: PERMISSION_MAP },
      component: () => null,
      title: 'Map View',
      pageTitle: 'General Connectivity Map Viewer',
    },
  ],
};
